import { useState } from 'react';
import { Alert, Button, Form, Modal, Spinner } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import LintResultsModal from './LintResultsModal';
import useBuildingImportHook from './useBuildingImportHook';

type Props = {
  onClose: () => void;
  buildingId: string;
};

const ReUploadModal = ({ onClose, buildingId }: Props) => {
  const {
    zipSelect,
    zipFileName,
    selectedZipString,
    fileInputError,
    showSuccessText,
    reUploadData,
    loading,
    lintBuilding,
    showLintResults,
    setShowLintResults,
    lintData,
    uploadError,
    reUploadZipFile,
  } = useBuildingImportHook(buildingId);
  const [shouldLintBuilding, setShouldLintBuilding] = useState<boolean>(true);

  const uploadErrors = () => {
    return uploadError ? <Alert variant="danger">{`Error: ${uploadError}`}</Alert> : null;
  };
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Re-Upload Building</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="add-building">
          <Form>
            <Form.Label>Lint building?</Form.Label>
            <Form.Check type="switch" id="published" onChange={() => setShouldLintBuilding(!shouldLintBuilding)} checked={shouldLintBuilding} />
          </Form>
          <h5>Upload IMDF Zip File</h5>
          <Dropzone onDrop={zipSelect}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} accept="application/zip" />
                <p>Drag and drop or click to select file</p>
              </div>
            )}
          </Dropzone>
          <div className="stored-file-element">{`Selected File: ${zipFileName}`}</div>
          {fileInputError ? <div className="file-error-element">{`Input Error: ${fileInputError}`}</div> : null}
          <Button
            onClick={() => {
              shouldLintBuilding ? lintBuilding() : reUploadZipFile();
            }}
            disabled={loading || !selectedZipString}
          >
            {loading ? <Spinner animation="border" /> : 'Upload Zip'}
          </Button>
          {uploadErrors()}
          {showSuccessText && reUploadData ? (
            <Alert variant="success">{`Sucessfully Re-Uploaded ${reUploadData.name} : ${reUploadData.id}`}</Alert>
          ) : null}
          <Modal centered show={showLintResults} onHide={() => setShowLintResults(false)} size="lg">
            <LintResultsModal
              onClose={() => setShowLintResults(false)}
              data={lintData?.lintBuilding}
              onUpload={() => {
                setShowLintResults(false);
                reUploadZipFile();
              }}
            />
          </Modal>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onClose()} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ReUploadModal;
