import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'react-bootstrap';
import pckg from '../../package.json';

const Login = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="App">
      <header className="App-header">
        <h1>IMDF Studio {pckg.version}</h1>
        <Button onClick={() => loginWithRedirect()}>Log In</Button>
      </header>
    </div>
  );
};

export default Login;
