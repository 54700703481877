import { ILintItem } from 'goodmaps-utils';
import { Button, Modal, Table } from 'react-bootstrap';
import './LintResultsModal.scss';

type Props = {
  onClose: () => void;
  data: ILintItem[];
  onUpload: () => void;
};

const LintResultsModal = ({ onClose, data, onUpload }: Props) => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Failed Lint Results</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th>Name</th>
              <th>Note</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d: ILintItem, i) => (
              <tr key={i}>
                <td>{d.name}</td>
                <td>{d.note}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onUpload()} variant="primary">
          Upload
        </Button>
        <Button onClick={() => onClose()} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </>
  );
};

export default LintResultsModal;
