import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthorizedApolloProvider from './AuthorizedApolloProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Auth0Provider
      useRefreshTokens
      cacheLocation="localstorage"
      domain="dev-gmr3zqw0.us.auth0.com"
      clientId="oe9NUhwYFq5ujztQ8QbKXh1NiX5utoG0"
      redirectUri={window.location.origin}
      audience="https://www.goodmaps.io/api"
    >
      <AuthorizedApolloProvider>
        <App />
      </AuthorizedApolloProvider>
    </Auth0Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
