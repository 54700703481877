import { useState, useEffect } from 'react';
import { QueryResult, useMutation } from '@apollo/client';
import { GET_BUILDINGS, UPDATE_BUILDING_METADATA } from '../queries';
import { buildingEditFormProperties } from './EditModal';
import { MapEditPayload, IAddressFeature, IMap } from 'goodmaps-utils';

type Props = {
  buildingId: string;
  getBuildingQuery: QueryResult;
  buildingData: IMap | undefined;
};

const useEditMutation = ({ buildingId, getBuildingQuery, buildingData }: Props) => {
  const [updateBuilding, { data, loading, error }] = useMutation(UPDATE_BUILDING_METADATA, {
    refetchQueries: [{ query: GET_BUILDINGS }],
    onCompleted: () => {
      getBuildingQuery.refetch();
    },
  });

  const [editError, setEditError] = useState<string>('');

  //handle any errors when performing an edit
  useEffect(() => {
    if (error) setEditError(error.message);
  }, [error]);

  const onConfirmEdit = async (values: buildingEditFormProperties) => {
    //reset editError state, if it is populated
    if (editError) setEditError('');
    if (buildingData?.features) {
      //build address object
      const address: IAddressFeature = {
        id: buildingData.features.address.id,
        buildingId: buildingId,
        feature_type: 'address',
        properties: values.address,
        type: 'Feature',
      };
      //build edit payload object
      const map: MapEditPayload = {
        id: buildingId,
        name: values.name,
        orgId: values.orgId,
        published: values.published,
        private: values.private,
        cpsRegion: values.cpsRegion,
        address,
      };

      //call updateBuilding mutation
      await updateBuilding({ variables: { map } });
    } else {
      //building data was not fully fetched correctly throw an edit error
      setEditError('building data was not fully fetched correctly, cannot perform edit, please reload the form.');
    }
  };

  return {
    onConfirmEdit,
    isMutationLoading: loading,
    editData: data?.updateMapMetaData,
    editError: editError,
  };
};

export default useEditMutation;
