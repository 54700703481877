import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const AcceptInvite = () => {
  const { loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();

  const organization = searchParams.get('organization');
  const invitation = searchParams.get('invitation');

  useEffect(() => {
    if (!invitation && !organization) {
      return;
    }

    if (!invitation && !!organization) {
      loginWithRedirect({ organization });
      return;
    }

    if (!!invitation && !!organization) {
      loginWithRedirect({ invitation, organization });
    }
  }, [invitation, organization, loginWithRedirect]);

  if (!invitation || !organization) return null;

  return <div onClick={() => loginWithRedirect({ invitation, organization })}> Accept Invite</div>;
};

export default AcceptInvite;
