import { Button, Modal, Spinner, Alert } from 'react-bootstrap';
import useDeleteModalHook from './useDeleteModalHook';

type Props = {
  buildingId: string;
  buildingName: string;
  onClose: () => void;
};

const DeleteModal = ({ buildingId, buildingName, onClose }: Props) => {
  const { onConfirmDeletion, data, loading, error } = useDeleteModalHook();

  const deletionErrors = () => {
    return error ? <Alert variant="danger">{`Error: ${error.message}`}</Alert> : null;
  };

  const deletionSuccessMessage = () => {
    return data ? <Alert variant="success">{`Sucessfully Deleted ${data.name} : ${data.id}`}</Alert> : null;
  };

  return (
    <div className="delete-modal-body">
      <Modal.Header closeButton>
        <Modal.Title>{`Delete Building: "${buildingName}"`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete "{buildingName}"?</p>
        {deletionSuccessMessage()}
        {deletionErrors()}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onClose()} variant="secondary">
          Close
        </Button>
        <Button variant="danger" disabled={loading || data} onClick={() => onConfirmDeletion(buildingId)}>
          {loading ? <Spinner animation="border" /> : 'Confirm'}
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default DeleteModal;
