import { Button, Modal } from 'react-bootstrap';
import BuildingImport from './BuildingImport';

type Props = {
  onClose: () => void;
};

const AddModal = ({ onClose }: Props) => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Add Building</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BuildingImport />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onClose()} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </>
  );
};

export default AddModal;
