import { gql } from '@apollo/client';
import { IMap, Organization } from 'goodmaps-utils';

export const GET_BUILDINGS = gql`
  query GetBuildings {
    getAllBuildings {
      id
      name
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  query getOrganizations {
    getAuth0Organizations {
      id
      display_name
    }
  }
`;

export interface GetOrganizationsResponse {
  getAuth0Organizations: Organization[];
}

export const POST_BUILDING_FROM_ZIP = gql`
  mutation CreateBuildingFromZip($zipString: String!) {
    createBuildingFromZip(zipString: $zipString) {
      id
      name
    }
  }
`;

export const PUT_BUILDING_FROM_ZIP = gql`
  mutation ReuploadBuildingFromZip($zipString: String!, $buildingId: String!) {
    reuploadBuildingFromZip(zipString: $zipString, mapId: $buildingId) {
      id
      name
    }
  }
`;

export const DELETE_BUILDING = gql`
  mutation DeleteMap($mapId: String!) {
    deleteMap(mapId: $mapId) {
      id
      name
    }
  }
`;

export const UPDATE_BUILDING_METADATA = gql`
  mutation UpdateMapMetaData($map: MapEditDto!) {
    updateMapMetaData(map: $map) {
      id
      name
    }
  }
`;

export interface GetBuildingResponse {
  getBuildingById: IMap;
}

export const UPDATE_UNIT = gql`
  mutation ($featureData: EditUnitFeatureDto!) {
    updateUnit(featureData: $featureData) {
      id
      properties {
        name {
          en
        }
      }
    }
  }
`;

export const UPDATE_AMENITY = gql`
  mutation ($featureData: EditAmenityFeatureDto!) {
    updateAmenity(featureData: $featureData) {
      id
      properties {
        name {
          en
        }
      }
    }
  }
`;

export const UPDATE_FIXTURE = gql`
  mutation ($featureData: EditFixtureFeatureDto!) {
    updateFixture(featureData: $featureData) {
      id
      properties {
        name {
          en
        }
      }
    }
  }
`;

export const UPDATE_OPENING = gql`
  mutation ($featureData: EditOpeningFeatureDto!) {
    updateOpening(featureData: $featureData) {
      id
      properties {
        name {
          en
        }
      }
    }
  }
`;

export const GET_POIS = gql`
  query ($buildingId: String!) {
    getBuildingById(buildingId: $buildingId) {
      id
      name
      cpsRegion
      features {
        amenity {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            accessibility
            name {
              en
              esMX
              frCA
            }
            alt_name {
              en
              frCA
            }
            hours
            phone
            website
            unit_ids
            address_id
            correlation_id
            level_id
            display_point {
              coordinates
              type
            }
            gm_ref
          }
        }
        fixture {
          id
          buildingId
          type
          geometry {
            type
            coordinates
          }
          feature_type
          properties {
            category
            name {
              en
              esMX
              frCA
            }
            alt_name {
              en
              frCA
            }
            anchor_id
            level_id
            display_point {
              type
              coordinates
            }
            restriction
            accessibility
          }
        }
        opening {
          id
          buildingId
          type
          geometry {
            type
          }
          feature_type
          properties {
            category
            accessibility
            access_control
            door {
              type
              automatic
              material
            }
            name {
              en
              esMX
              frCA
            }
            alt_name {
              en
              frCA
            }
            display_point {
              coordinates
              type
            }
            level_id
          }
        }
      }
    }
  }
`;

export const GET_ROOMS = gql`
  query ($buildingId: String!) {
    getBuildingById(buildingId: $buildingId) {
      id
      name
      cpsRegion
      features {
        unit {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            accessibility
            name {
              en
              esMX
              frCA
            }
            alt_name {
              en
              frCA
            }
            level_id
            display_point {
              coordinates
              type
            }
          }
        }
      }
    }
  }
`;

export const GET_BUILDING = gql`
  query ($buildingId: String!) {
    getBuildingById(buildingId: $buildingId) {
      id
      name
      orgId
      published
      private
      cpsRegion
      features {
        address {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            address
            unit
            locality
            province
            country
            postal_code
            postal_code_ext
            postal_code_vanity
          }
        }
        amenity {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            accessibility
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            hours
            phone
            website
            unit_ids
            address_id
            correlation_id
            level_id
            display_point {
              coordinates
              type
            }
          }
        }
        anchor {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            address_id
            unit_id
          }
        }
        building {
          id
          buildingId
          type
          feature_type
          geometry {
            coordinates
            type
          }
          properties {
            name {
              frCA
              en
            }
            alt_name {
              frCA
              en
            }
            category
            restriction
            display_point {
              type
              coordinates
            }
            address_id
          }
        }
        detail {
          id
          buildingId
          type
          feature_type
          geometry {
            coordinates
            type
          }
          properties {
            level_id
          }
        }
        fixture {
          id
          buildingId
          type
          geometry {
            type
            coordinates
          }
          feature_type
          properties {
            category
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            anchor_id
            level_id
            display_point {
              type
              coordinates
            }
            restriction
            accessibility
          }
        }
        footprint {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            name {
              en
              frCA
            }
            building_ids
          }
        }
        geofence {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            correlation_id
            display_point {
              coordinates
              type
            }
            building_ids
            level_ids
            parents
            accessibility
            fixture_ids
            opening_ids
          }
        }
        kiosk {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            anchor_id
            level_id
            display_point {
              coordinates
              type
            }
          }
        }
        level {
          id
          buildingId
          type
          geometry {
            type
            coordinates
          }
          feature_type
          properties {
            category
            restriction
            outdoor
            ordinal
            name {
              en
              frCA
            }
            short_name {
              en
              frCA
            }
            display_point {
              coordinates
              type
            }
            address_id
            building_ids
            elevation
          }
        }
        occupant {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            name {
              en
              frCA
            }
            category
            anchor_id
            hours
            phone
            website
            validity
            correlation_id
          }
        }
        opening {
          id
          buildingId
          type
          geometry {
            type
          }
          feature_type
          properties {
            category
            accessibility
            access_control
            door {
              type
              automatic
              material
            }
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            display_point {
              coordinates
              type
            }
            level_id
          }
        }
        relationship {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          properties {
            category
            direction
            origin {
              id
              feature_type
            }
            intermediary {
              id
              feature_type
            }
            destination {
              id
              feature_type
            }
            hours
          }
          feature_type
        }
        section {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            accessibility
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            display_point {
              coordinates
              type
            }
            level_id
            address_id
            correlation_id
            parents
          }
        }
        unit {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            accessibility
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            level_id
            display_point {
              coordinates
              type
            }
          }
        }
        venue {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            category
            restriction
            name {
              en
              frCA
            }
            alt_name {
              en
              frCA
            }
            hours
            phone
            website
            address_id
            display_point {
              coordinates
              type
            }
          }
        }

        cps_points {
          id
          buildingId
          type
          geometry {
            coordinates
            type
          }
          feature_type
          properties {
            indoorMark
            cps
            x_pointcloud
            y_pointcloud
            z_pointcloud
            level
          }
        }
        route {
          id
          properties {
            category
            restriction
            accessibility
            level_id
          }
          geometry {
            coordinates
            type
          }
        }
      }
    }
  }
`;

export const LINT_BUILDING_FROM_ZIP = gql`
  mutation LintBuilding($zipString: String!) {
    lintBuilding(zipString: $zipString) {
      name
      status
      note
    }
  }
`;
