import { Formik } from 'formik';
import { Button, Modal, Spinner, Alert, Form, Row, Col } from 'react-bootstrap';
import useEditMutation from './useEditMutation';
import useBuildingQueryHook from './useBuildingQueryHook';
import * as Yup from 'yup';
import './EditModal.scss';

type Props = {
  buildingId: string;
  onClose: () => void;
};

const schema = Yup.object({
  name: Yup.string().required('Please provide a valid name.'),
  orgId: Yup.string().required('Please select an Organization.'),
  published: Yup.boolean().required(),
  private: Yup.boolean().required(),
  cpsRegion: Yup.string().optional(),
  address: Yup.object({
    address: Yup.string().required('Please provide a valid address.'),
    country: Yup.string().required('Please provide a valid country.'),
    locality: Yup.string().required('Please provide a valid locality.'),
    postal_code: Yup.string().required('Please provide a valid postal code.'),
    province: Yup.string().required('Please provide a valid province.'),
  }).required(),
});

export type buildingEditFormProperties = {
  name: string;
  orgId: string;
  published: boolean;
  private: boolean;
  cpsRegion: string;
  address: {
    address: string;
    country: string;
    locality: string;
    postal_code: string;
    province: string;
  };
};

const EditModal = ({ buildingId, onClose }: Props) => {
  const { getBuildingQuery, isQueryLoading, buildingData, organizations, populatingFormError, initialBuildingData } = useBuildingQueryHook({
    buildingId,
  });

  const { onConfirmEdit, isMutationLoading, editData, editError } = useEditMutation({
    buildingId,
    getBuildingQuery,
    buildingData,
  });

  const editSuccessMessage = () => {
    return editData ? <Alert variant="success">{`Successfully Edited ${editData.name}`}</Alert> : null;
  };

  const editErrors = () => {
    return editError ? <Alert variant="danger">{`Unsuccessful Edit: ${editError}`}</Alert> : null;
  };

  return (
    <div className="edit-modal-body">
      <Modal.Header closeButton>
        <Modal.Title>{isQueryLoading || populatingFormError ? null : `Edit Building: ${buildingData?.name || ''}`}</Modal.Title>
      </Modal.Header>
      {isQueryLoading ? (
        <div className="loader-container">
          <Spinner animation="border" />
        </div>
      ) : (
        <Modal.Body>
          {populatingFormError ? (
            <Alert variant="danger">{`Error: ${populatingFormError}`}</Alert>
          ) : (
            <Formik
              enableReinitialize
              initialValues={initialBuildingData}
              validationSchema={schema}
              onSubmit={values => {
                onConfirmEdit(values);
              }}
            >
              {formik => (
                <Form id="editBuildingForm" onSubmit={formik.handleSubmit}>
                  <Row>
                    <Form.Group>
                      <Form.Label>Name*</Form.Label>
                      <Form.Control as="input" id="name" {...formik.getFieldProps('name')} />
                      {formik.touched.name && formik.errors.name ? <div className="form-feedback-error">{formik.errors.name}</div> : null}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Label>CPS Region*</Form.Label>
                      <Form.Select id="cpsRegion" {...formik.getFieldProps('cpsRegion')}>
                        <option value="">Select A CPS Region</option>
                        <option key={'us'} value={'us'}>
                          US
                        </option>
                        <option key={'uk'} value={'uk'}>
                          UK
                        </option>
                        <option key={'az'} value={'az'}>
                          Azure
                        </option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Org Id*</Form.Label>
                      <Form.Select id="orgId" {...formik.getFieldProps('orgId')}>
                        <option value="">Select An Organization</option>
                        {organizations?.map(organization => {
                          return (
                            <option key={organization.id} value={organization.id}>
                              {organization.display_name}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {formik.touched.orgId && formik.errors.orgId ? <div className="form-feedback-error">{formik.errors.orgId}</div> : null}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Label>Address*</Form.Label>
                      <Form.Control as="input" id="address" {...formik.getFieldProps('address.address')} />
                      {formik.touched.address?.address && formik.errors.address?.address ? (
                        <div className="form-feedback-error">{formik.errors.address?.address}</div>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Locality*</Form.Label>
                      <Form.Control as="input" id="locality" {...formik.getFieldProps('address.locality')} />
                      {formik.touched.address?.locality && formik.errors.address?.locality ? (
                        <div className="form-feedback-error">{formik.errors.address?.locality}</div>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Postal Code*</Form.Label>
                      <Form.Control as="input" id="postal_code" {...formik.getFieldProps('address.postal_code')} />
                      {formik.touched.address?.postal_code && formik.errors.address?.postal_code ? (
                        <div className="form-feedback-error">{formik.errors.address?.postal_code}</div>
                      ) : null}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Label>Country*</Form.Label>
                      <Form.Control as="input" id="country" {...formik.getFieldProps('address.country')} />
                      {formik.touched.address?.country && formik.errors.address?.country ? (
                        <div className="form-feedback-error">{formik.errors.address?.country}</div>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Province*</Form.Label>
                      <Form.Control as="input" id="province" {...formik.getFieldProps('address.province')} />
                      {formik.touched.address?.province && formik.errors.address?.province ? (
                        <div className="form-feedback-error">{formik.errors.address?.province}</div>
                      ) : null}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Search</Form.Label>
                      <Button
                        style={{ display: 'block' }}
                        variant="primary"
                        onClick={() => {
                          window.open(
                            `https://www.google.com/maps/place/${formik.values.address.address} ${formik.values.address.locality} ${formik.values.address.postal_code} ${formik.values.address.country}`,
                          );
                        }}
                      >
                        Google Maps
                      </Button>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col}>
                      <Form.Label>Published</Form.Label>
                      <Form.Check type="switch" id="published" onChange={formik.handleChange} checked={formik.values.published} />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label>Private</Form.Label>
                      <Form.Check type="switch" id="private" onChange={formik.handleChange} checked={formik.values.private} />
                    </Form.Group>
                  </Row>
                  {editSuccessMessage()}
                  {editErrors()}
                  <hr></hr>
                  <div className="footer">
                    <Button style={{ marginRight: '0.25rem' }} onClick={() => onClose()} variant="secondary">
                      Close
                    </Button>
                    <Button
                      form="editBuildingForm"
                      type="submit"
                      variant="primary"
                      disabled={isMutationLoading || !(!isQueryLoading && !populatingFormError) || !formik.dirty}
                    >
                      {isMutationLoading ? <Spinner animation="border" /> : 'Confirm'}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      )}
    </div>
  );
};

export default EditModal;
