import { useState, useEffect } from 'react';
import { POST_BUILDING_FROM_ZIP, GET_BUILDINGS, LINT_BUILDING_FROM_ZIP, PUT_BUILDING_FROM_ZIP } from '../queries';
import { useMutation } from '@apollo/client';

const useBuildingImportHook = (buildingId?: string) => {
  const [zipFileName, setZipFileName] = useState<string>('');
  const [selectedZipString, setSelectedZipString] = useState<string>('');
  const [fileInputError, setFileInputError] = useState<string>('');
  const [showSuccessText, setShowSuccessText] = useState<boolean>(false);
  const [showLintResults, setShowLintResults] = useState<boolean>(false);
  const [postBuildingFromZip, { data, loading, error }] = useMutation(POST_BUILDING_FROM_ZIP, {
    refetchQueries: [{ query: GET_BUILDINGS }],
  });
  const [lintBuildingFromZip, { data: lintData, loading: lintLoading, error: lintError, reset: resetLint }] = useMutation(LINT_BUILDING_FROM_ZIP);
  const [putBuldingFromZip, { data: reuploadData, loading: reuploadLoading, error: reuploadError }] = useMutation(PUT_BUILDING_FROM_ZIP);

  useEffect(() => {
    //if mutation upload error, reset zip in state
    if (error) {
      resetInputZipState();
    }
  }, [error]);

  const lintBuilding = async () => {
    await lintBuildingFromZip({ variables: { zipString: selectedZipString } });
    setShowLintResults(true);
  };

  const zipSelect = (acceptedFiles: File[]) => {
    if (showSuccessText) setShowSuccessText(false);
    //check if inputted file is a zip file
    if (acceptedFiles[0].type === 'application/zip' || acceptedFiles[0].type === 'application/x-zip-compressed') {
      //reset input error if it exists, configure zip in state
      if (fileInputError) setFileInputError('');
      setBase64StringForZip(acceptedFiles[0]);
    } else {
      //show file input error and reset zip in state
      setFileInputError('Must input a .zip file');
      resetInputZipState();
    }
  };

  const setBase64StringForZip = (zipFile: File) => {
    let reader = new FileReader();
    reader.onabort = () => setFileInputError('file reading was aborted');
    reader.onerror = () => setFileInputError('file reading has failed');
    reader.onloadend = () => {
      //configure base64 zip string and set zip in state
      const zipString = (reader.result as string).replace('data:', '').replace(/^.+,/, '');
      setZipFileName(zipFile.name);
      setSelectedZipString(zipString);
    };
    reader.readAsDataURL(zipFile);
  };

  const uploadZipFile = async () => {
    //call POST_BUILDING_FROM_ZIP mutation using graphQL and show result
    await postBuildingFromZip({ variables: { zipString: selectedZipString } });
    setShowSuccessText(true);
    resetInputZipState();
  };

  const reUploadZipFile = async () => {
    await putBuldingFromZip({ variables: { zipString: selectedZipString, buildingId: buildingId } });
    setShowSuccessText(true);
    resetInputZipState();
  };

  const resetInputZipState = () => {
    setZipFileName('');
    setSelectedZipString('');
  };

  return {
    zipSelect,
    zipFileName,
    selectedZipString,
    fileInputError,
    uploadZipFile,
    showSuccessText,
    data: data?.createBuildingFromZip,
    loading: loading || lintLoading || reuploadLoading,
    uploadError: error?.message || reuploadError?.message,
    reUploadData: reuploadData?.reuploadBuildingFromZip,
    lintBuilding,
    showLintResults,
    setShowLintResults,
    lintData,
    reUploadZipFile,
  };
};

export default useBuildingImportHook;
