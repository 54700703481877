import './App.css';
import { useAuth0 } from '@auth0/auth0-react';
import BuildingList from './screens/BuildingList';
import EntityList from './screens/EntityList';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './screens/Login';
import Building from './screens/Building';
import AcceptInvite from './screens/AcceptInvite';
import BuildingUnity from './screens/BuildingUnity';

function App() {
  const { isAuthenticated } = useAuth0();
  if (!isAuthenticated) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<AcceptInvite />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<BuildingList />} />
        <Route path="/building/:buildingId/entities" element={<EntityList />} />
        <Route path=":buildingId" element={<BuildingUnity />} />
        <Route path="sdk/:buildingId" element={<Building />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
