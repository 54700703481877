export enum CRUDMode {
  'none' = 0,
  'add',
  'edit',
  'delete',
  'reupload',
}

export enum EntityCRUDMode {
  'none' = 0,
  'editPoi',
  'editRoom',
}
