import { useState } from 'react';
import { useMutation, QueryResult } from '@apollo/client';
import { UPDATE_AMENITY, UPDATE_FIXTURE, UPDATE_OPENING } from '../queries/index';
import { editPOIFormProperties } from './PoiEditModal';
import {
  IAmenityFeature,
  IFixtureFeature,
  IOpeningFeature,
  amenityCategory,
  fixtureCategory,
  openingCategory,
  AmenityEditPayload,
  FixtureEditPayload,
  OpeningEditPayload,
} from 'goodmaps-utils';

type Props = {
  poiId: string;
  getPOIsQuery: QueryResult;
};

type POIFeature = IAmenityFeature | IFixtureFeature | IOpeningFeature;

const usePoiEditMutation = ({ poiId, getPOIsQuery }: Props) => {
  const [isMutationLoading, setIsMutationLoading] = useState(false);

  const [updateAmenity, { data: amenityUpdateData, error: amenityUpdateError }] = useMutation(UPDATE_AMENITY, {
    onCompleted: () => {
      getPOIsQuery.refetch();
    },
  });

  const [updateFixture, { data: fixtureUpdateData, error: fixtureUpdateError }] = useMutation(UPDATE_FIXTURE, {
    onCompleted: () => {
      getPOIsQuery.refetch();
    },
  });

  const [updateOpening, { data: openingUpdateData, error: openingUpdateError }] = useMutation(UPDATE_OPENING, {
    onCompleted: () => {
      getPOIsQuery.refetch();
    },
  });

  const editAmenity = async (amenity: AmenityEditPayload) => {
    try {
      await updateAmenity({ variables: { featureData: amenity } });
    } catch (e) {
      console.log(e);
    } finally {
      setIsMutationLoading(false);
    }
  };

  const editFixture = async (fixture: FixtureEditPayload) => {
    try {
      await updateFixture({ variables: { featureData: fixture } });
    } catch (e) {
      console.log(e);
    } finally {
      setIsMutationLoading(false);
    }
  };

  const editOpening = async (opening: OpeningEditPayload) => {
    try {
      await updateOpening({ variables: { featureData: opening } });
    } catch (e) {
      console.log(e);
    } finally {
      setIsMutationLoading(false);
    }
  };

  const onConfirmPOIEdit = async (values: editPOIFormProperties) => {
    setIsMutationLoading(true);
    if (getPOIsQuery.data) {
      const selectedPoi: POIFeature | undefined = [
        ...getPOIsQuery.data.getBuildingById.features.amenity,
        ...getPOIsQuery.data.getBuildingById.features.fixture,
        ...getPOIsQuery.data.getBuildingById.features.opening,
      ].find((poi: POIFeature) => poi.id === poiId);
      if (selectedPoi) {
        switch (selectedPoi.feature_type) {
          case 'amenity':
            await editAmenity({
              feature_type: selectedPoi.feature_type,
              id: selectedPoi.id,
              buildingId: selectedPoi.buildingId,
              properties: {
                category: values.category as amenityCategory,
                name: { frCA: selectedPoi.properties.name?.frCA, en: values.name, esMX: values.spanishName },
              },
            });
            break;
          case 'fixture':
            await editFixture({
              feature_type: selectedPoi.feature_type,
              id: selectedPoi.id,
              buildingId: selectedPoi.buildingId,
              properties: {
                category: values.category as fixtureCategory,
                name: { frCA: selectedPoi.properties.name?.frCA, en: values.name, esMX: values.spanishName },
              },
            });
            break;
          case 'opening':
            await editOpening({
              feature_type: selectedPoi.feature_type,
              id: selectedPoi.id,
              buildingId: selectedPoi.buildingId,
              properties: {
                category: values.category as openingCategory,
                name: { frCA: selectedPoi.properties.name?.frCA, en: values.name, esMX: values.spanishName },
              },
            });
            break;
        }
      }
    }
  };

  return {
    onConfirmPOIEdit,
    isPoiMutationLoading: isMutationLoading,
    editedPoiName:
      amenityUpdateData?.updateAmenity.properties.name?.en ||
      fixtureUpdateData?.updateFixture.properties.name?.en ||
      openingUpdateData?.updateOpening.properties.name?.en,
    editedPoiId: amenityUpdateData?.updateAmenity.id || fixtureUpdateData?.updateFixture.id || openingUpdateData?.updateOpening.id,
    editPoiError: amenityUpdateError?.message || fixtureUpdateError?.message || openingUpdateError?.message,
  };
};

export default usePoiEditMutation;
