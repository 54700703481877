import { Alert, Button, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import usePoiQueryHook from './usePoiQueryHook';
import usePoiEditMutation from './usePoiEditMutation';
import * as Yup from 'yup';
import './PoiEditModal.scss';

type Props = {
  poiId: string;
  onClose: () => void;
};

export const editPOIFormSchema = Yup.object({
  name: Yup.string().required('Please provide a valid name.'),
  spanishName: Yup.string().notRequired(),
  category: Yup.string().required('Please provide a valid category.'),
});

export type editPOIFormProperties = Yup.InferType<typeof editPOIFormSchema>;

const PoiEditModal = ({ poiId, onClose }: Props) => {
  const { getPOIsQuery, isQueryLoading, poiName, populatingFormError, categories, initialValues } = usePoiQueryHook({ poiId });

  const { onConfirmPOIEdit, isPoiMutationLoading, editedPoiName, editPoiError } = usePoiEditMutation({ poiId, getPOIsQuery });

  const editSuccessMessage = () => {
    return editedPoiName ? <Alert variant="success">{`Successfully Edited ${editedPoiName}`}</Alert> : null;
  };

  const editErrors = () => {
    return editPoiError ? <Alert variant="danger">{`Unsuccessful Edit: ${editPoiError}`}</Alert> : null;
  };

  return (
    <div className="edit-poi-modal-body">
      <Modal.Header closeButton>
        <Modal.Title>{isQueryLoading || populatingFormError ? null : `Edit POI: ${poiName}`}</Modal.Title>
      </Modal.Header>
      {isQueryLoading ? (
        <div className="loader-container">
          <Spinner animation="border" />
        </div>
      ) : (
        <Modal.Body>
          {populatingFormError ? (
            <Alert variant="danger">{`Error: ${populatingFormError}`}</Alert>
          ) : (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={editPOIFormSchema}
              onSubmit={values => {
                onConfirmPOIEdit(values);
              }}
            >
              {formik => (
                <Form id="editPOIForm" onSubmit={formik.handleSubmit}>
                  <Row>
                    <Form.Group>
                      <Form.Label>Name*</Form.Label>
                      <Form.Control as="input" id="name" {...formik.getFieldProps('name')} />
                      {formik.touched.name && formik.errors.name ? <div className="form-feedback-error">{formik.errors.name}</div> : null}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group>
                      <Form.Label>Spanish Name</Form.Label>
                      <Form.Control as="input" id="spanishName" {...formik.getFieldProps('spanishName')} />
                      {formik.touched.spanishName && formik.errors.spanishName ? (
                        <div className="form-feedback-error">{formik.errors.spanishName}</div>
                      ) : null}
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group>
                      <Form.Label>Category*</Form.Label>
                      <Form.Select id="category" {...formik.getFieldProps('category')}>
                        <option value="">Select A Category</option>
                        {categories.map(category => {
                          return (
                            <option key={category} value={category}>
                              {category}
                            </option>
                          );
                        })}
                      </Form.Select>
                      {formik.touched.category && formik.errors.category ? <div className="form-feedback-error">{formik.errors.category}</div> : null}
                    </Form.Group>
                  </Row>
                  {editSuccessMessage()}
                  {editErrors()}
                  <hr></hr>
                  <div className="footer">
                    <Button style={{ marginRight: '0.25rem' }} onClick={() => onClose()} variant="secondary">
                      Close
                    </Button>
                    <Button
                      form="editPOIForm"
                      type="submit"
                      variant="primary"
                      disabled={isPoiMutationLoading || !(!isQueryLoading && !populatingFormError) || !formik.dirty}
                    >
                      {isPoiMutationLoading ? <Spinner animation="border" /> : 'Confirm'}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      )}
    </div>
  );
};

export default PoiEditModal;
