import { Button, Spinner, Alert, Form, Modal } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import useBuildingImportHook from './useBuildingImportHook';
import './BuildingImport.scss';
import LintResultsModal from './LintResultsModal';
import { useState } from 'react';

const BuildingImport = () => {
  const {
    zipSelect,
    zipFileName,
    selectedZipString,
    fileInputError,
    uploadZipFile,
    showSuccessText,
    data,
    loading,
    uploadError,
    lintBuilding,
    showLintResults,
    setShowLintResults,
    lintData,
  } = useBuildingImportHook();
  const [shouldLintBuilding, setShouldLintBuilding] = useState<boolean>(true);

  const uploadErrors = () => {
    return uploadError ? <Alert variant="danger">{`Error: ${uploadError}`}</Alert> : null;
  };

  return (
    <div className="add-building">
      <Form>
        <Form.Label>Lint building?</Form.Label>
        <Form.Check type="switch" id="lint-switch" onChange={() => setShouldLintBuilding(!shouldLintBuilding)} checked={shouldLintBuilding} />
      </Form>
      <h5>Upload IMDF Zip File</h5>
      <Dropzone onDrop={zipSelect}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} accept="application/zip" />
            <p>Drag and drop or click to select file</p>
          </div>
        )}
      </Dropzone>
      <div className="stored-file-element">{`Selected File: ${zipFileName}`}</div>
      {fileInputError ? <div className="file-error-element">{`Input Error: ${fileInputError}`}</div> : null}
      <Button onClick={shouldLintBuilding ? lintBuilding : uploadZipFile} disabled={loading || !selectedZipString}>
        {loading ? <Spinner animation="border" /> : 'Upload Zip'}
      </Button>
      {uploadErrors()}
      {showSuccessText && data ? <Alert variant="success">{`Sucessfully Created ${data.name} : ${data.id}`}</Alert> : null}
      <Modal centered show={showLintResults} onHide={() => setShowLintResults(false)} size="lg">
        <LintResultsModal
          onClose={() => setShowLintResults(false)}
          data={lintData?.lintBuilding}
          onUpload={() => {
            setShowLintResults(false);
            uploadZipFile();
          }}
        />
      </Modal>
    </div>
  );
};

export default BuildingImport;
