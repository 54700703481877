import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ROOMS } from '../queries/index';
import { EnumToArray } from '../helper/GoodMaps';
import { editRoomFormProperties } from './RoomEditModal';
import { IUnitFeature, unitCategoryEnum } from 'goodmaps-utils';

type Props = {
  roomId: string;
};

export interface getRoomsQueryPayload {
  getBuildingById: {
    id: string;
    parentId: string;
    features: {
      unit: IUnitFeature[];
    };
    name: string;
    orgId: string;
    published: boolean;
    private: boolean;
  };
}

const useRoomQueryHook = ({ roomId }: Props) => {
  const [roomName, setRoomName] = useState('');
  const [spanishMXRoomName, setSpanishMXRoomName] = useState('');
  const [category, setCategory] = useState('');

  //pull buildingId from params
  let { buildingId } = useParams();
  //getRoomQuery
  const getRoomsQuery = useQuery<getRoomsQueryPayload>(GET_ROOMS, { variables: { buildingId }, notifyOnNetworkStatusChange: true });

  //fill state variables with query result
  useEffect(() => {
    if (getRoomsQuery.data) {
      const selectedRoom: IUnitFeature | undefined = getRoomsQuery.data.getBuildingById.features.unit.find(
        (room: IUnitFeature) => room.id === roomId,
      );
      if (selectedRoom) {
        setRoomName(selectedRoom.properties.name?.en || '');
        setSpanishMXRoomName(selectedRoom.properties.name?.esMX || '');
        setCategory(selectedRoom.properties.category);
      }
    }
  }, [getRoomsQuery.data, roomId]);

  const initialValues: editRoomFormProperties = {
    name: roomName,
    spanishName: spanishMXRoomName,
    category: category,
  };

  return {
    getRoomsQuery,
    isQueryLoading: getRoomsQuery.loading,
    roomName,
    populatingFormError: getRoomsQuery.error?.message,
    categories: EnumToArray(unitCategoryEnum),
    initialValues,
  };
};

export default useRoomQueryHook;
