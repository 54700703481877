import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GetBuildingResponse, GetOrganizationsResponse, GET_BUILDING, GET_ORGANIZATIONS } from '../queries';
import { buildingEditFormProperties } from './EditModal';

type Props = {
  buildingId: string;
};

const useBuildingQueryHook = ({ buildingId }: Props) => {
  const getOrganizationsQuery = useQuery<GetOrganizationsResponse>(GET_ORGANIZATIONS, { notifyOnNetworkStatusChange: true });
  const getBuildingQuery = useQuery<GetBuildingResponse>(GET_BUILDING, { variables: { buildingId }, notifyOnNetworkStatusChange: true });

  const [isQueryLoading, setIsQueryLoading] = useState<boolean>(false);
  const [populatingFormError, setPopulatingFormError] = useState<string>('');

  //handle any errors when populating the form
  useEffect(() => {
    if (getBuildingQuery.error) setPopulatingFormError(`${getBuildingQuery.error.message} (getBuildingQuery)`);
    else if (getOrganizationsQuery.error) setPopulatingFormError(`${getOrganizationsQuery.error.message} (getOrganizationsQuery)`);
    else if (populatingFormError) setPopulatingFormError('');
  }, [getBuildingQuery.error, getOrganizationsQuery.error, populatingFormError]);

  //handle loading for the form
  useEffect(() => {
    if (getBuildingQuery.loading || getOrganizationsQuery.loading) setIsQueryLoading(true);
    else setIsQueryLoading(false);
  }, [getBuildingQuery.loading, getOrganizationsQuery.loading]);

  //TODO: use Studio's transform function here
  //initial values for formik
  const initialBuildingData: buildingEditFormProperties = {
    name: getBuildingQuery.data?.getBuildingById.name || '',
    orgId: getBuildingQuery.data?.getBuildingById.orgId || '',
    published: getBuildingQuery.data?.getBuildingById.published || false,
    private: getBuildingQuery.data?.getBuildingById.private || false,
    cpsRegion: getBuildingQuery.data?.getBuildingById.cpsRegion || '',
    address: {
      address: getBuildingQuery.data?.getBuildingById.features?.address.properties.address || '',
      country: getBuildingQuery.data?.getBuildingById.features?.address.properties.country || '',
      locality: getBuildingQuery.data?.getBuildingById.features?.address.properties.locality || '',
      postal_code: getBuildingQuery.data?.getBuildingById.features?.address.properties.postal_code || '',
      province: getBuildingQuery.data?.getBuildingById.features?.address.properties.province || '',
    },
  };

  return {
    getBuildingQuery,
    isQueryLoading,
    buildingData: getBuildingQuery.data?.getBuildingById,
    organizations: getOrganizationsQuery.data?.getAuth0Organizations,
    populatingFormError,
    initialBuildingData,
  };
};

export default useBuildingQueryHook;
