import { useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { GetBuildingResponse, GET_BUILDING } from '../queries';
import ExploreIMDFBuilding from 'explore-sdk/dist/ExploreIMDFBuilding';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { WebGLRenderer, MOUSE } from 'three';
import { useParams } from 'react-router-dom';
import './Building.scss';
import Loading from '../components/Loading';
import Alert from 'react-bootstrap/Alert';
import { Container } from 'react-bootstrap';
import NavBar from '../components/NavBar';

interface BuildingProps {
  buildingId: string;
}

const Building = () => {
  const container = useRef<any>();
  const { buildingId } = useParams();
  const { loading, error, data } = useQuery<GetBuildingResponse>(GET_BUILDING, { variables: { buildingId } });

  try {
    useEffect(() => {
      if (data?.getBuildingById) {
        try {
          container.current.innerHTML = '';
          const renderer = new WebGLRenderer({ antialias: true });
          container.current.appendChild(renderer.domElement);
          const { clientWidth, clientHeight } = container.current;
          let controls: OrbitControls;
          const building = new ExploreIMDFBuilding(data.getBuildingById);
          building.debug = true;
          console.log('Floors: ', building.floors.length);
          building.createRenderer(
            renderer as any,
            clientWidth,
            clientHeight,
            r => {
              controls = new OrbitControls(r.getCamera(), renderer.domElement);
              controls.enableDamping = true;
              controls.dampingFactor = 0.25;
              controls.enableRotate = true;
              controls.enablePan = true;
              controls.minDistance = 10;
              controls.maxDistance = 500;
              controls.maxPolarAngle = Math.PI / 5;
              // controls.minPolarAngle = -Math.PI / 2;
              controls.target.set(0, 0, 0);
              r.getCamera().position.set(0, 0, 50);
              r.getCamera().lookAt(0, 0, 0);
              controls.update();
              // controls.screenSpacePanning = true;
              controls.mouseButtons = {
                RIGHT: MOUSE.ROTATE,
                MIDDLE: MOUSE.DOLLY,
                LEFT: MOUSE.PAN,
              };
            },
            r => {
              // const v = r.getCoordinateFromRaycaster(x, y);
              // currentCoords.x = v.x;
              // currentCoords.y = v.y;
              // setMousePosition([v.x, v.y]);
              // if (controls && r.snapToUser) controls.target = r.currentCameraTarget;
              // if (controls) controls.update();
            },
          );

          const onResize = () => {
            // const { clientWidth, clientHeight } = container.current;
            // renderer.setSize(clientWidth, clientHeight);
            // if (building && building.renderer) {
            //   building.renderer.getCamera().aspect = clientWidth / clientHeight;
            //   building.renderer.getCamera().updateProjectionMatrix();
            // }
            controls.update();
          };
          window.addEventListener('resize', onResize);

          setTimeout(() => {
            console.log('render floor');
            building.renderer?.renderFloor(building.floors[0]);
          }, 3000);
          return () => {
            // window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('resize', onResize);
            controls.dispose();
          };
        } catch (e) {
          console.log(e);
        }
      }
    }, [data]);

    const renderData = () => {
      if (loading) {
        return <Loading />;
      }
      if (error) {
        console.log(error);
        return (
          <>
            <div>{(error?.networkError as any).result.errors[0].message}</div>
          </>
        );
      }
      return (
        <div className="map-container">
          <div className="building-name">{data?.getBuildingById.name}</div>
          <div className="map-viewer" ref={container} />
        </div>
      );
    };

    return (
      <>
        <NavBar />
        {renderData()}
      </>
    );
  } catch (e) {
    return (
      <>
        <NavBar />
        <Container>
          <Alert variant="danger">
            <Alert.Heading>Error loading building!</Alert.Heading>
            <p>This was unexpected. Report it to our dev team here.</p>
          </Alert>
        </Container>
      </>
    );
  }
};

export default Building;
