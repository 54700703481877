import { useAuth0 } from '@auth0/auth0-react';
import { Button, Navbar, Container, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface INavBarProps {
  beforeNavigate?: () => Promise<any>;
}

const NavBar = ({ beforeNavigate }: INavBarProps) => {
  const { logout } = useAuth0();
  const uNavigate = useNavigate();

  const navigate = async (path: string) => {
    if (beforeNavigate) {
      await beforeNavigate();
    }
    uNavigate(path);
  };

  return (
    <Navbar variant="dark" bg="dark" sticky="top">
      <Container fluid>
        <Navbar.Brand onClick={() => navigate('/')}>Studio</Navbar.Brand>
        <div style={{ flex: 1 }}>
          <Nav className="me-auto">
            <Nav.Link onClick={() => navigate('/')}>Buildings</Nav.Link>
          </Nav>
        </div>
        <Button onClick={() => logout({ returnTo: window.location.origin })}>Log Out</Button>
      </Container>
    </Navbar>
  );
};

export default NavBar;
