import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_POIS } from '../queries/index';
import { EnumToArray } from '../helper/GoodMaps';
import { editPOIFormProperties } from './PoiEditModal';
import {
  IAmenityFeature,
  IFixtureFeature,
  IOpeningFeature,
  amenityCategory,
  fixtureCategory,
  openingCategory,
  amenityCategoryEnum,
  fixtureCategoryEnum,
  openingCategoryEnum,
} from 'goodmaps-utils';

type Props = {
  poiId: string;
};

export type POIFeature = IAmenityFeature | IFixtureFeature | IOpeningFeature;
type poiCategory = amenityCategory | fixtureCategory | openingCategory;

export interface getPOIsQueryPayload {
  getBuildingById: {
    id: string;
    parentId: string;
    features: {
      amenity: IAmenityFeature[];
      fixture: IFixtureFeature[];
      opening: IOpeningFeature[];
    };
    name: string;
    orgId: string;
    published: boolean;
    private: boolean;
  };
}

const usePoiEditModal = ({ poiId }: Props) => {
  const [poiName, setPOIName] = useState('');
  const [poiSpanishMXName, setPOISpanishMXName] = useState('');
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState<poiCategory[]>([]);

  //pull buildingId from params
  let { buildingId } = useParams();
  //get allPOIs query
  const getPOIsQuery = useQuery<getPOIsQueryPayload>(GET_POIS, { variables: { buildingId }, notifyOnNetworkStatusChange: true });

  //fill state variables with query result
  useEffect(() => {
    if (getPOIsQuery.data) {
      const selectedPoi: POIFeature | undefined = [
        ...getPOIsQuery.data.getBuildingById.features.amenity,
        ...getPOIsQuery.data.getBuildingById.features.fixture,
        ...getPOIsQuery.data.getBuildingById.features.opening,
      ].find((poi: POIFeature) => poi.id === poiId);
      if (selectedPoi) {
        setPOIName(selectedPoi.properties.name?.en || '');
        setPOISpanishMXName(selectedPoi.properties.name?.esMX || '');
        switch (selectedPoi.feature_type) {
          case 'amenity':
            setCategories(EnumToArray(amenityCategoryEnum));
            break;
          case 'fixture':
            setCategories(EnumToArray(fixtureCategoryEnum));
            break;
          case 'opening':
            setCategories(EnumToArray(openingCategoryEnum));
            break;
        }
        setCategory(selectedPoi.properties.category);
      }
    }
  }, [getPOIsQuery.data, poiId]);

  const initialValues: editPOIFormProperties = {
    name: poiName,
    spanishName: poiSpanishMXName,
    category: category,
  };

  return {
    getPOIsQuery,
    isQueryLoading: getPOIsQuery.loading,
    poiName,
    populatingFormError: getPOIsQuery.error?.message,
    categories,
    initialValues,
  };
};

export default usePoiEditModal;
