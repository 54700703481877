import { useState } from 'react';
import { useMutation, QueryResult } from '@apollo/client';
import { UPDATE_UNIT } from '../queries/index';
import { editRoomFormProperties } from './RoomEditModal';
import { IUnitFeature, unitCategory, UnitEditPayload } from 'goodmaps-utils';

type Props = {
  roomId: string;
  getRoomsQuery: QueryResult;
};

const useRoomEditMutation = ({ roomId, getRoomsQuery }: Props) => {
  const [isMutationLoading, setIsMutationLoading] = useState(false);

  //updateRoom mutation
  const [updateFeature, { data, error }] = useMutation(UPDATE_UNIT, {
    onCompleted: () => {
      getRoomsQuery.refetch();
    },
  });

  //room edit function
  const onConfirmRoomEdit = async (values: editRoomFormProperties) => {
    setIsMutationLoading(true);
    if (getRoomsQuery.data) {
      const selectedRoom: IUnitFeature | undefined = getRoomsQuery.data.getBuildingById.features.unit.find(
        (room: IUnitFeature) => room.id === roomId,
      );
      if (selectedRoom) {
        const updatedFeatureObject: UnitEditPayload = {
          feature_type: selectedRoom.feature_type,
          id: selectedRoom.id,
          buildingId: selectedRoom.buildingId,
          properties: {
            category: values.category as unitCategory,
            name: { frCA: selectedRoom.properties.name?.frCA, en: values.name, esMX: values.spanishName },
          },
        };
        try {
          await updateFeature({ variables: { featureData: updatedFeatureObject } });
        } catch (e) {
          console.log(e);
        } finally {
          setIsMutationLoading(false);
        }
      }
    }
  };

  return {
    onConfirmRoomEdit,
    isRoomMutationLoading: isMutationLoading,
    editRoomData: data?.updateUnit,
    editRoomError: error?.message,
  };
};

export default useRoomEditMutation;
