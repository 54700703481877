import Spinner from 'react-bootstrap/Spinner';

const Loading = () => {
  return (
    <div style={{ height: 'calc(100vh - 58px)', width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Loading;
