/* eslint-disable react-hooks/rules-of-hooks */
import { useAuth0 } from '@auth0/auth0-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import NavBar from '../components/NavBar';
import config from '../globals/config/config';
import useBuildingQueryHook from '../components/useBuildingQueryHook';
import RoomEditModal from '../components/RoomEditModal';

const BuildingWebGLToken = () => {
  const { buildingId } = useParams();
  const { buildingData, isQueryLoading } = useBuildingQueryHook({ buildingId: buildingId as string });
  const { getAccessTokenSilently } = useAuth0();
  const { addEventListener, removeEventListener, unityProvider, sendMessage, isLoaded, loadingProgression, unload } = useUnityContext({
    loaderUrl: 'studio-unity-build/Build/studio-unity-build.loader.js',
    dataUrl: 'studio-unity-build/Build/studio-unity-build.data',
    frameworkUrl: 'studio-unity-build/Build/studio-unity-build.framework.js',
    codeUrl: 'studio-unity-build/Build/studio-unity-build.wasm',
  });

  const [selectedFloor, setSelectedFloor] = useState<string | undefined>('Select floor');
  const [selectedPoiToEdit, SetPoiToEdit] = useState<string | undefined>('');

  useEffect(() => {
    addEventListener('SetPoiToEdit', SetPoiToEdit);

    return () => {
      removeEventListener('SetPoiToEdit', SetPoiToEdit);
    };
  }, [addEventListener, removeEventListener, SetPoiToEdit]);

  const RenderEditPoiModal = () => {
    console.log(`React says: ${selectedPoiToEdit} type: ${typeof selectedPoiToEdit}`);

    return <RoomEditModal roomId={selectedPoiToEdit as string} onClose={() => SetPoiToEdit('')} />;
  };

  useEffect(() => {
    const sendDataToUnity = async () => {
      sendMessage('ReactMessageManager', 'SetBuilding', `${buildingId}`);
    };

    if (isLoaded) sendDataToUnity();
  }, [isLoaded]);

  useEffect(() => {
    if (buildingData !== null) {
      const bottomLevel = buildingData?.features?.level.slice().sort((l1, l2) => l1.properties.ordinal - l2.properties.ordinal)[0].properties
        .name?.en;

      setSelectedFloor(bottomLevel);
    }
  }, [buildingData]);

  const floorDropdownItems = buildingData?.features?.level
    .slice()
    .sort((l1, l2) => l1.properties.ordinal - l2.properties.ordinal)
    .map((level, i) => {
      const name = level.properties.name?.en;
      const id = level.id;
      return (
        <Dropdown.Item
          eventKey={i}
          onClick={() => {
            console.log(id);
            setSelectedFloor(name);
            sendMessage('BridgeManager', 'ChangeBuildingFloor', id);
          }}
        >
          {name}
        </Dropdown.Item>
      );
    });

  return (
    <>
      <NavBar beforeNavigate={async () => await unload()} />
      <div className="map-container">
        {isLoaded && (
          <div className="unity-ui-container">
            <DropdownButton
              className="floor-selector-dropdown"
              variant="secondary"
              title={isQueryLoading ? 'Loading...' : `Selected floor: ${selectedFloor}`}
            >
              {floorDropdownItems}
            </DropdownButton>
            <div>{selectedPoiToEdit && selectedPoiToEdit !== '' && RenderEditPoiModal()}</div>
          </div>
        )}
        <Fragment>
          {!isLoaded && <p>Loading Application... {Math.round(loadingProgression * 100)}%</p>}
          <Unity
            unityProvider={unityProvider}
            style={{ height: 'calc(100vh - 58px)', width: '100vw', visibility: isLoaded ? 'visible' : 'hidden', pointerEvents: 'all' }}
          />
        </Fragment>
      </div>
    </>
  );
};

export default BuildingWebGLToken;
