import { useMutation } from '@apollo/client';
import { DELETE_BUILDING, GET_BUILDINGS } from '../queries';

const useDeleteModalHook = () => {
  const [deleteMap, { data, loading, error }] = useMutation(DELETE_BUILDING, {
    refetchQueries: [{ query: GET_BUILDINGS }],
  });

  const onConfirmDeletion = async (buildingId: string) => {
    //call delete mutation
    await deleteMap({ variables: { mapId: buildingId } });
  };

  return {
    onConfirmDeletion,
    data: data?.deleteMap,
    loading,
    error,
  };
};

export default useDeleteModalHook;
